export const getPermittedMenuConfig = ({ menuConfig = [], userContext, entityContext }) => {
  const { user, role } = userContext;
  const entitlements = entityContext?.entitlements;

  if (!user || !role || !entitlements) {
    return menuConfig;
  }

  const filterConfig = config => {
    // Check if the config has permissionsApp and if the user has the required entitlements
    if (config?.permissionsApp?.length) {
      if (!entitlements?.apps?.some(entitlement => config.permissionsApp.includes(entitlement.crn))) {
        return false;
      }
    }

    // Check if the config has permissionsSKU and if the user has the required entitlements
    if (config?.permissionsSKU?.length) {
      if (!entitlements?.entitlements?.some(entitlement => config.permissionsSKU.includes(entitlement.crn))) {
        return false;
      }
    }

    if (user?.isPlatformAdmin || user?.isPlatformSupport) return true;

    // Check if the config has permissions and if the user has the required role permissions
    const configPermissions = [].concat(config?.permissions).filter(permission => !!permission);
    if (configPermissions?.length) {
      if (role?.permissions?.include?.('*')) return true;
      return configPermissions?.[config.permissionStrategy || 'some']?.(p => role?.permissions?.includes(p));
    }

    // Filter the children of the config
    if (config?.children) {
      config.children = config.children.filter(filterConfig);

      return config.children?.length;
    }

    return true;
  };

  // Temporary workaround to support both menuConfig shapes until everyone has changed to the new format.
  const permitted = (menuConfig?.sidebarOptions || menuConfig).filter(filterConfig);

  if (menuConfig.sidebarOptions) {
    return {
      ...menuConfig,
      sidebarOptions: permitted,
    };
  }

  return permitted;
};
